/**
 * Copyright: Copyright © 2024
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RedirectService } from '../../service/redirect.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../service/auth.service';
import { SessionService } from '../../service/session.service';
import { UserModel } from 'src/app/model/user.model';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/config/constants';
const strings = require('../../shared/utility/strings/strings.en.json');

/*
 * @author    @version    @date           @description
 * JVeerasa   01          Mar 01, 2023    AFLL-16881 - VDS Portal | Upgrade Angular to v15 release
 * HSenevir   02          May 11, 2023    AFLL-17913 Fixed checkbox disable functionality after Angular 15 upgrade
 * EElangav   03          Feb 15, 2024    AFLL-20711 ANZ | Localize Privacy Policy copy  for a HCP user
 * PPareek    04          Aug 23, 2024    AFLL-22061 - VDS Portal | Privacy Policy Acceptance Checkbox is disabled
 * HSenevir   05          Sep 16, 2024    AFLL-22021 - Refactored country selection logic
 */

@Component({
  selector: 'app-privacy-acceptance',
  templateUrl: './privacy-acceptance.html',
  styleUrls: ['./privacy-acceptance.scss']
})
export class PrivacyAcceptanceComponent implements OnInit {

  public isSubmitted = false;
  public checked = false;
  public privacyAndTermsForm: UntypedFormGroup;
  public alreadyReachedBottom = false;
  public userSession: UserModel;
  public country = '';

  constructor(
    private router: Router,
    private redirectService: RedirectService,
    private toastService: ToastrService,
    private authService: AuthService,
    private sessionService: SessionService
  ) { }

  public ngOnInit(): void {
    this.privacyAndTermsForm = new UntypedFormGroup({
      accepted: new UntypedFormControl({value: false, disabled: true})
    });
    this.userSession = this.sessionService.getUserSession();
    this.setCountry();
  }

  public setCountry(): void {
    if (AppConstants.isANZRegionHostname) {
      this.country = 'ANZ';
    } else if (AppConstants.isUKHostname) {
      this.country = 'UK';
    } else {
      this.country = 'US';
    }
  }

  public goToLogin(): void {
    this.router.navigateByUrl(this.redirectService.urls.login);
  }

  public submitForm(): void {
    this.isSubmitted = true;
    const profile = this.sessionService.getUserSession();
    this.authService.acceptPrivacyPolicy(profile.refreshToken, response => {
      this.authService.processSuccessfulAuthNavigation();
    }, error => {
      this.isSubmitted = false;
      this.toastService.error(strings.general_error, 'Error');
    });
  }

  onScrolledToBottom(event): void {
    const target = event.target;
    const scrollTop = target.scrollTop;
    const tolerance = 1;
    const scrollBottomReachedTop = target.scrollHeight - target.clientHeight;
    const isScrolledToBottom = scrollTop + tolerance >= scrollBottomReachedTop;
    if (!this.alreadyReachedBottom && isScrolledToBottom) {
      this.alreadyReachedBottom = true;
      this.privacyAndTermsForm.get('accepted').enable();
    }
  }
}
