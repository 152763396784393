/**
 * Copyright: Copyright © 2021
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { SessionService } from '../../service/session.service';
import { ValidatorService } from '../../service/validator.service';
import { RedirectService } from '../../service/redirect.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStatus } from '../../model/user.model';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { CookieUtilityServiceService } from '../../service/cookie-utility-service.service';
import { AppConstants } from '../../../config/constants';

/*
 * @author          @version    @date             @description
 * JVeerasa         01          Mar 01, 2023      AFLL-16881 - VDS Portal | Upgrade Angular to v15 release
 * HSenevir         02          Mar 13, 2023      AFLL-16968 - Updated VDP navigation to happen after logout success/failure
 * HSenevir         03          Mar 16, 2023      AFLL-16968 - Added copyright header
 * HSenevir         04          Sep 14, 2023      AFLL-18353 - Supporting DocSpera mobile app login with VDP login flow
 * HSenevir         05          Jan 29, 2024      AFLL-20257 - Enabled Okta session logout
 * HSenevir         06          Feb 28, 2024      AFLL-20908 - Fixing hcp app re-login issue with ds flag and related cookie
 * PPareek          07          Apr 23, 2024      AFLL-21289 - VPP & Vi | User Unable to Login via https://velys.jnj/ (without www)
 * PPareek          08          Apr 27, 2024      AFLL-21186 - Remove isVDP if/else code from our services.
 * PPareek          09          Oct 08, 2024      AFLL-22356 - Changes done to set up Unit Test
 */

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  isSubmitted = false;
  isInactiveLogout = false;
  isSessionValid = true;
  // is coming from Velys Insights mobile app
  isFromDSMobileApp = false;
  isLoginFormShown = false;

  auth_login_invalid_credentials: string;
  auth_login_simultaneous_access: string;
  auth_login_password_reset: string;
  auth_login_locked: string;

  constructor(private authService: AuthService,
              private sessionService: SessionService,
              private formBuilder: UntypedFormBuilder,
              private validatorService: ValidatorService,
              private redirectService: RedirectService,
              private toastService: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              public translateService: TranslateService,
              private cookieUtilityService: CookieUtilityServiceService
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [this.validatorService.email, Validators.required]],
      password: [''],
      isLoginFromMobile: [false]
    });

    // translation section for the toast Service

    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('login.auth_login_invalid_credentials').subscribe((translated: string) => {
        this.auth_login_invalid_credentials = translated;
      });
      this.translateService.get('login.auth_login_simultaneous_access').subscribe((translated: string) => {
        this.auth_login_simultaneous_access = translated;
      });
      this.translateService.get('login.auth_login_password_reset').subscribe((translated: string) => {
        this.auth_login_password_reset = translated;
      });
      this.translateService.get('login.auth_login_locked').subscribe((translated: string) => {
        this.auth_login_locked = translated;
      });
    });
  }

  ngOnInit(): void {
    const inactiveParam: string = this.route.snapshot.queryParamMap.get('inactivity');
    const dsParam: string = this.route.snapshot.queryParamMap.get('ds');
    const authorizationCodeErrorStatus: string = this.route.snapshot.queryParamMap.get('acError');

    // Redirect to www.velys.jnj if the host is velys.jnj
    if (location.host === AppConstants.redirectingUSProdPublicHost) {
      this.redirectService.navigate(AppConstants.validUSProdPublicURL + location.hash);
      return;
    }

    // Before clearing insights app related cookie, obtaining if this was from mobile app from cookie value itself.
    this.isFromDSMobileApp = this.authService.isLoadingFromDocsperaMobileApp();
    if (inactiveParam && inactiveParam.toLowerCase() === 'true') {
      this.isInactiveLogout = true;
      this.isLoginFormShown = true;
      this.prePopulateEmail();
    }
    if (dsParam && dsParam.toLowerCase() === 'true') {
      this.isFromDSMobileApp = true;
      this.loginForm.get('isLoginFromMobile').patchValue(true);
    }
    if (authorizationCodeErrorStatus && authorizationCodeErrorStatus.toLocaleLowerCase() === 'true') {
      this.translateService.get('login.auth_login_invalid_credentials').subscribe((translated: string) => {
        this.toastService.error(translated, 'Error');
      });
    }
    const sessionInterruptedParam: string = this.route.snapshot.queryParamMap.get('sessionInterrupted');
    if (sessionInterruptedParam && sessionInterruptedParam.toLowerCase() === 'true') {
      this.isSessionValid = false;
      this.isLoginFormShown = true;
    }

    this.authService.logout(response => {
        const isNoticeMode = this.isInactiveLogout || !this.isSessionValid;
        if (!isNoticeMode) {
          if (this.authService.isOktaLogoutExecuted()) {
            this.fetchAndNavigateToAuthUrl();
          } else {
            this.authService.executeOktaLogout();
          }
        }
      },
      error => {
        this.authService.removeUserData();
        const isNoticeMode = this.isInactiveLogout || !this.isSessionValid;
        if (!isNoticeMode) {
          if (this.authService.isOktaLogoutExecuted()) {
            this.fetchAndNavigateToAuthUrl();
          } else {
            this.authService.executeOktaLogout();
          }
        }
      });
  }

  private fetchAndNavigateToAuthUrl(): void {
    if (this.isFromDSMobileApp) {
      /*
      * AFLL-12454 - Setting a 15-minute expiration for the cookie so that authorization flow can pick up the value
      * AFLL-20908 - Fixing re-login scenario to maintain the session for 1 day.
      * Note: This session is only for Insights mobile app.
      * */
      const seconds = 1000;
      const cookieExpirationDate = moment().add('1', 'd').unix() * seconds;
      this.cookieUtilityService.setCookie(this.cookieUtilityService.keys.ds, true, new Date(cookieExpirationDate));
    }
    this.authService.getAuthConfig((error) => {
      this.isLoginFormShown = true;
      this.translateService.get('login.authConfigFailed').subscribe((translated: string) => {
        this.auth_login_locked = translated;
      });
    });
  }

  public redirectToAuthUrl(): void {
    if (this.authService.isOktaLogoutExecuted()) {
      const authUrl = this.sessionService.getSessionValue(this.sessionService.sessionKeys.authUrl);
      if (authUrl) {
        this.redirectService.navigate(authUrl);
      } else {
        this.fetchAndNavigateToAuthUrl();
      }
    } else {
      this.authService.executeOktaLogout();
    }
  }

  public login(): void {
    this.isSubmitted = true;
    this.authService.login(this.loginForm.value, response => {
      const userProfile = this.sessionService.getUserSession();
      if (userProfile.status == UserStatus.PASSWORD_EXPIRED) {
        this.navigateToResetPassword(this.loginForm.get('password').value);
      } else {
        if (userProfile.status == UserStatus.MFA_ENROLL) {
          this.navigateToMFAActivate(this.loginForm.get('password').value);
        } else if (userProfile.status == UserStatus.MFA_REQUIRED) {
          this.navigateToMFASendVerificationCode(this.loginForm.get('password').value);
        } else {
          if (this.isFromDSMobileApp) {
            // AFLL-12454 - Setting a 15-minute expiration for the cookie so that authorization flow can pick up the value
            const cookieExpirationDate = moment().add('15', 'm').unix() * 1000;
            this.cookieUtilityService.setCookie(this.cookieUtilityService.keys.ds, true, new Date(cookieExpirationDate));
          }
          this.redirectService.navigate(userProfile.authorizationUrl);
        }
      }
    }, error => {
      this.isSubmitted = false;
      if (error.message && error.message.includes('locked')) {
        this.translateService.get('login.auth_login_locked').subscribe((translated: string) => {
          this.toastService.error(translated, 'Error');
        });
      } else if (error.message && error.message.toLowerCase().includes('password reset')) {
        this.translateService.get('login.auth_login_password_reset').subscribe((translated: string) => {
          this.toastService.error(translated, 'Error');
        });
      } else if (error.message && error.message.toLowerCase().includes('simultaneous')) {
        this.translateService.get('login.auth_login_simultaneous_access').subscribe((translated: string) => {
          this.toastService.error(translated, 'Error');
        });
      } else {
        this.translateService.get('login.auth_login_invalid_email')
          .subscribe((translated: string) => {
          this.toastService.error(translated, 'Error');
        });
      }
    });
  }

  private navigateToResetPassword(currentPassword): void {
    this.router.navigate([this.redirectService.urls.resetPassword], {state: {currentPassword}});
  }

  private navigateToMFASendVerificationCode(currentPassword): void {
    this.router.navigate(['/app-mfa-send-verification-codes'], {state: {currentPassword}});
  }

  private navigateToMFAActivate(currentPassword): void {
     this.router.navigate(['/MFA-component'], {state: {currentPassword}});
  }

  private prePopulateEmail(): void {
    const lastEmail = this.sessionService.getUserEmail() || '';
    this.loginForm.get('email').patchValue(lastEmail);
  }

  isInValidEmail(): string {
    const { errors }: any = this.loginForm.get('email');
    return (errors != undefined && errors.badFormat);
  }
}
