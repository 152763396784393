/**
 * Copyright: Copyright © 2024
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectService } from '../../service/redirect.service';
import { environment } from '../../../environments/environment';
import { UserModel } from '../../model/user.model';
import { SessionService } from '../../service/session.service';
import { AppConstants } from '../../../config/constants';
/*
 *  @author     @version    @date           @description
 *  EElangav    01          Feb 14, 2024    AFLL-20711 ANZ | Localize Privacy Policy copy  for a HCP user 
 *  PPareek     02          Oct 08, 2024    AFLL-22356 Changes done for Initial set up of Unit Test Case
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() public isAuthenticated = true;
  public year = new Date().getFullYear();
  public userSession: UserModel;
  public country: string;

  constructor(
    private router: Router,
    public urlRedirectService: RedirectService,
    private userSessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.userSession = this.userSessionService.getUserSession();
    this.setCountry();
  }

  public setCountry() {
    if (this.userSession && this.userSession.organization) {
      this.country = this.userSession.organization.country ? this.userSession.organization.country : environment.country;
      return;
    } else {
      if (AppConstants.isANZRegionHostname) {
        this.country = 'ANZ';
      } else if (AppConstants.isUKHostname) {
        this.country = 'UK';
      } else {
        this.country = 'US';
      }
      return;
    }
  }

  public goToPrivacyPolicyScreen(): void {
      this.router.navigateByUrl(this.urlRedirectService.urls.privacyPolicyCountryBased[this.country.toLowerCase()]);
  }

  public goToContactUsScreen(): void {
    this.router.navigateByUrl(this.urlRedirectService.urls.contactUs);
  }

}
