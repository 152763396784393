import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../../../app/service/session.service';
import { AuthService } from '../../../app/service/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mfa-send-verification-codes',
  templateUrl: './mfa-send-verification-codes.component.html',
  styleUrls: ['./mfa-send-verification-codes.component.scss']
})
export class MfaSendVerificationCodesComponent implements OnInit {

  private currentPassword: string;

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private toastService: ToastrService
  ) {
    if (this.router.getCurrentNavigation().extras.state &&
      this.router.getCurrentNavigation().extras.state.currentPassword) {
      this.currentPassword = this.router.getCurrentNavigation().extras.state.currentPassword;
    }
  }

  ngOnInit(): void {
  }

  public mfaSendCode(): void {
    this.authService.mfaSendCode(this.sessionService.getUserSession(), response => {
      this.navigateToMFAVerificationCode(this.currentPassword);
    }, error => {
      this.toastService.error('MFA verification code not sent', 'Error');
    });
  }

  public navigateToMFAVerificationCode(currentPassword): void {
    this.router.navigate(['/verification-code'], {state: {currentPassword}});
  }

}
